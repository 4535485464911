import styles from 'styles/modules/Register.module.scss';
import BlockContent from 'components/BlockContent';
import { registration } from 'lang/en.json';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SEO from 'next-seo.config';
import { NextSeo } from 'next-seo';
import { wrapper } from 'store';
import auth from 'utils/auth';

const Register = () => {
    const router = useRouter();
    return (
        <>
            <NextSeo {...SEO} titleTemplate={`Register | %s`} />
            <div className={['container', styles.pageRegister].join(' ')}>
                <div style={{ flex: 1 }}>
                    <div className={['row', styles.row].join(' ')}>
                        <BlockContent
                            theme="primary"
                            data={{
                                title: registration.start.title,
                                body: registration.start.body,
                                button: {
                                    title: registration.start.button,
                                },
                            }}
                            onClickRegisterJournalist={() => {
                                router.push({
                                    pathname: `/register/journalist`,
                                    query: router.query,
                                });
                            }}
                            onClick={() => {
                                // setFormData({ ...formData, type: 'company' });
                                router.push({
                                    pathname: `/register/profile`,
                                    query: router.query,
                                });
                            }}
                        />
                    </div>
                    <div className={styles.login}>
                        Already have a Newspage?{' '}
                        <Link href={'/login'}>
                            <a>Log in</a>
                        </Link>
                    </div>
                    <div className={styles.login}>
                        <Link
                            href={
                                'mailto:dominic@newspage.media?subject=We are a PR agency interested in using Newspage&body=Send us your agency’s name, URL and the best number to call you on and we’ll be in touch ASAP'
                            }
                        >
                            Are you a PR agency?
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export const getServerSideProps = wrapper.getServerSideProps(
    (store) =>
        async ({ req, res }) => {
            await auth(store, req, res);
            const state = store.getState().auth;
            if (state.isAuthenticated) {
                return {
                    redirect: {
                        destination: `/${state.company}`,
                        permanent: false,
                    },
                };
            }
            return {
                props: {},
            };
        }
);

export default wrapper.withRedux(Register);
