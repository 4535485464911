import styles from 'styles/modules/BlockContent.module.scss';
import Button from './Button';

const BlockContent = (props) => {
    const { data } = props;
    if (!data) return null;

    const theme = props.theme ?? 'white';

    return (
        <div
            className={['border-accent', styles.block, styles[theme]].join(' ')}
        >
            <h2>{data.title}</h2>
            <p>{data.body}</p>

            <div
                className={[
                    styles['block__buttons'],
                    styles['block__buttons--login'],
                ].join(' ')}
            >
                <Button
                    onClick={props.onClick}
                    theme={'primary'}
                    title={data.button.title}
                />

                <Button
                    onClick={props.onClickRegisterJournalist}
                    theme={'outline'}
                    title={"I'M A JOURNALIST"}
                />
            </div>
        </div>
    );
};

export default BlockContent;
